// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import './variables.scss';

*:focus {
    outline: none !important;
    box-shadow: none !important;
}

table,
thead,
tbody,
tfoot,
tr,
td,
th {
  border-width: initial !important;
}

html {
    body {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        color: rgba(0, 0, 0, 1);
        background-color: var(--oksijen-body);

        a {
            color: var(--oksijen-turuncu);
            text-decoration: none;

            &:hover {
                color: var(--oksijen-turuncu-hover);
                text-decoration: none;
            }
        }

        h1, h2, h3, h4, h5, h6 {
            font-family: "Raleway", sans-serif;
        }

        .dropdown{
            .dropdown-menu {
                min-width: 0 !important;
            }
        }

        .btn-outline-warning {
            border-color: var(--oksijen-turuncu);
            color: var(--oksijen-turuncu);
            &:hover{
                color: var(--oksijen-white);
                background-color: var(--oksijen-turuncu-hover);
                border-color: var(--oksijen-turuncu-hover);
            }
        }

        .border-warning {
            border-color: var(--oksijen-turuncu) !important;
        }
    }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/

@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

.btn-primary {
    --bs-btn-color: rgba(255, 255, 255, 1);
    --bs-btn-bg: var(--oksijen-turuncu);
    --bs-btn-border-color: var(--oksijen-turuncu);
    --bs-btn-hover-color: rgba(255, 255, 255, 1);
    --bs-btn-hover-bg: var(--oksijen-turuncu-hover);
    --bs-btn-hover-border-color: var(--oksijen-turuncu-hover);
    --bs-btn-focus-shadow-rgb: 44, 122, 187;
    --bs-btn-active-color: rgba(255, 255, 255, 1);
    --bs-btn-active-bg: var(--oksijen-turuncu-hover);
    --bs-btn-active-border-color: var(--oksijen-turuncu-hover);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: rgba(255, 255, 255, 1);
    --bs-btn-disabled-bg: var(--oksijen-turuncu);
    --bs-btn-disabled-border-color: var(--oksijen-turuncu);
    color: var(--bs-btn-color);
}

.btn-oksijen, .btn-warning {
    background-color: var(--oksijen-turuncu);
    color: rgba(255, 255, 255, 1);
    border-color: var(--oksijen-turuncu);
    &:hover{
        background-color: var(--oksijen-turuncu-hover);
        color: rgba(255, 255, 255, 1);
        border-color: var(--oksijen-turuncu-hover);
    }
}

.text-warning {
    color: var(--oksijen-turuncu) !important;
}

.text-primary {
    color: var(--oksijen-mavi) !important;
}

.bg-warning {
    background-color: var(--oksijen-turuncu) !important;
}

.modal-backdrop {
    z-index: -1;
    background-color: var(--oksijen-modal-backdrop);
}

.bg-oksijen-mavi {
    background-color: var(--oksijen-mavi);
    color: rgba(255, 255, 255, 1);
    &:hover{
        background-color: var(--oksijen-mavi-dark);;
        color: rgba(255, 255, 255, 1);
    }
}

// img {
//     width: 100% !important;
// }
