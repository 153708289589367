// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: rgb(247, 151, 70);
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$oksijen-turuncu: rgb(247, 149, 69);
$oksijen-mavi: #0d4d95;

:root{
    --oksijen-turuncu: rgb(247, 151, 70);
    --oksijen-turuncu-hover: rgba(148, 90, 42, 1);
    --oksijen-mavi: rgb(13, 77, 149);
    --oksijen-mavi-dark: rgb(10, 61, 119);
    --oksijen-mavi-light: rgb(37, 94, 159);
    --oksijen-turuncu-rgba: rgba(247, 151, 70, 1);
    --oksijen-mavi-rgba: rgba(13, 77, 149, 1);
    --oksijen-modal-backdrop: rgba(13, 77, 149, 0.5);
    --oksijen-white: rgba(255, 255, 255, 1);
    --oksijen-yesil: #38c172;
    --oksijen-body: #f8fafc;
    --oksijen-card: #d3dae2;
}
